/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500;600&display=swap');
/* Add application styles & imports to this file! */
// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import 'custom-theme.css';

/* font-family: 'Mukta', sans-serif; */

html, body {
    height: 100vh;
}

.mat-h3 {
	margin-left:20px;
}

.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}
  
.example-small-width {
  width: 220px;
}

.full-width {
  width: 50%;
}

.flex-style-inline {
	display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
	float:left;
}

.container{
  height: auto;
}
.container1{
  height: 100vh;
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-grid-tile .mat-figure {
  justify-content: flex-start !important ;
}

.green-snackbar{
  background: #4CAF50 !important;
}

.pt-8{
  padding-top: 8px !important;
}

.font-bold{
  font-weight: bold;
}

.mr-20{
  margin-right: 20px;
}

.mr-5{
  margin-right: 5px;
}

.mat-row:nth-child(even){
  
  background-color: white;
}
      
.mat-row:nth-child(odd){
  background-color: #f8f8f9;
}

::ng-deep{
  .k-grid tr:nth-child(even){
    background-color: white;
  }
        
  .k-grid tr:nth-child(odd){
    
    background-color: #f8f8f9;
  }
}
.mat-elevation-z1{
  box-shadow: none;
}
tr.mat-header-row{
  height: 45px !important;
}
tr.mat-row, tr.mat-footer-row {
  height: 56px !important;
}
.mat-cell, .mat-footer-cell {
  color: #6f6f6fde;
}
th.mat-header-cell{
    border-top-width: 1px; 
    border-top-style: solid;
    border-top-color: rgba(0,0,0,.12);
}
td.mat-cell{
  border-bottom-width: unset !important;
  border-bottom-style: none !important;
}


.view-action-link{
  color: hsl(216deg 100% 57%);
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}

 td.mat-cell{
   font-family: "Mukta",sans-serif;
   color: #000 !important;
   height: 70px;
}
table.mat-table {
  border: 1px solid #d4d4d4;
}

tr.mat-header-row {
  height: 70px !important;
}

tr.mat-header-row th{
  font-size: 15px !important;
  font-family: "Mukta",sans-serif;
  font-weight: 500;
  color: black;
  letter-spacing: 0.5px;
}

td.mat-column-quantity{
  color: rgb(60, 51, 145) !important;
}

// kendo grid css
.k-grid{
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
  border-width: 1px;
  // border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-grid-table tr.ng-star-inserted{
  height: 70px;
}
.k-grid-table tr.ng-star-inserted input.k-checkbox{
  height: 15px;
  width: 15px;
}

.k-grid-header .k-header{
  border-top: 1px solid rgba(0,0,0,.12);
  border-bottom: 1px solid rgba(0,0,0,.12);
}
.k-grid td{
  // border-color: rgba(0, 0, 0, 0.08);
  border-width: 0 0 0px 1px;
  vertical-align: middle;
  padding: 8px 12px;
  border-style: solid;
  outline: 0;
  font-weight: inherit;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-grid th{
  padding: 8px 12px;
  border-width: 0 0 1px 1px;
  white-space: nowrap;
  border-style: solid;
  border-color: inherit;
  outline: 0;
  font-weight: inherit;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-grid table{
  margin: 0;
  width: 100%;
  max-width: none;
  border-width: 0;
  border-color: inherit;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  outline: 0;
}

.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
  color: #6f6f6fde;
}

th.k-header{
  font-size: 15px !important;
  font-family: "Mukta",sans-serif;
  font-weight: 500;
  color: black;
  letter-spacing: 0.5px;
}

th.k-header input.k-checkbox{
  height: 15px;
  width: 15px;
}

.margin-25{
  margin: 25px 25px 25px 0px !important;
}

.mr-10{
  margin-right: 10px !important;
}

.mat-row.clickable:hover {
  background: #97959538;
  cursor: pointer;
}

span.mat-placeholder-required{
  color: red;
  font-weight: bolder;
}

mat-chip.cls-chip-status{
  background-color: white !important;
  border: 1px solid #d3c7c7;
}

.icon-info{
  cursor: pointer;
}